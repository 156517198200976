import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { AssembledComponentSkuTile } from './AssembledComponentSkuTile';
import { BestPrice } from './BestPrice';
import { Button, ButtonVariant } from './Button';
import { CartSku } from './CartSku';
import { Container } from './Container';
import { de } from './i18n/locales/de';
import { en } from './i18n/locales/en';
import { es } from './i18n/locales/es';
import { fr } from './i18n/locales/fr';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { ModalHeader } from './Modal';
import { OffcanvasHeader } from './Offcanvas';
import { ThemeColor } from './pages/TemplatePage';
import { PriceBlock } from './PriceBlock';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { QuantityInputGroup } from './QuantityInputGroup';
import { reportWebVitals } from './reportWebVitals';
import { ServiceContactsSubpage } from './ServiceContactsSubpage';

b2x.init<ThemeColor, ButtonVariant, IconName, IconSize>({
  api: {
    apiKey: 'GIFAHIIAABHBACDICADEHG',
    productPopulate: {
      alternativeImages: true,
      attributeGroups: true,
      attributes: true,
      breadcrumbs: true,
      priceRange: true,
      skus: {
        measurement: true,
        price: true,
      },
      variantProducts: {
        skus: {
          measurement: true,
          price: true,
        },
      },
    },
    productTilePopulate: {
      alternativeImages: true,
      assembledComponents: true,
      attributes: true,
      priceRange: true,
      skus: {
        measurement: true,
        price: true,
      },
      variantProducts: {
        skus: {
          measurement: true,
          price: true,
        },
      },
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              assembledComponents: {
                sku: {
                  product: true,
                },
              },
              sku: {
                attributes: true,
                product: {
                  attributes: true,
                },
              },
            },
          },
          shippingProfile: true,
        },
        customer: true,
        wishlist: { products: true, skus: { product: true } },
      },
    },
  },
  cookiebot: {
    id: '35e7097c-34f4-4a47-9002-f4d86f53f88c',
  },
  defaultButtonVariant: 'primary',
  // countriesLocalesMap: {
  //   AT: 'de',
  //   BE: 'fr',
  //   DE: 'de',
  //   ES: 'es',
  //   FR: 'fr',
  //   IT: 'it',
  // },
  enableBestPrice: true,

  enableCientSideComingSoonManagement: false,
  enableWishlist: true,
  forceImageDensity: 1.5,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-primary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  hotjar: {
    id: '5348438',
  },
  i18n: { de, en, es, fr, it },
  icons: {
    arrow: {
      bottom: 'small-arrow-down',
      left: 'small-arrow-left',
      right: 'small-arrow-right',
      top: 'small-arrow-up',
    },
    close: 'delete',
    configurator: 'filter',
    discount: 'discount',
    download: 'small-arrow-down',
    info: 'info',
    logout: 'logout',
    passwordToggle: {
      hidden: 'hidden',
      visible: 'visible',
    },
    shipping: 'shipping',
  },
  imagePlaceholder: {
    backgroundColor: '#f6f6f6',
  },
  includeFreeShippingInSavingAmount: true,
  preRelease: true,
  productImageAspectRatio: 1,
  projectName: 'dececco',
  ui: 'bs5',
  variants: {
    AccountOffcanvas: 'A',
    AddressFieldset: 'A',
    AssembledComponentSkuTile: AssembledComponentSkuTile,
    BestPrice: BestPrice,
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartSku: CartSku,
    ChangePasswordForm: 'A',
    Container: Container,
    CustomerAddressForm: 'A',
    CustomerForm: 'B',
    InvoiceFieldset: 'A',
    LocaleForm: 'A',
    LoginForm: 'A',
    ModalHeader: ModalHeader,
    OffcanvasHeader: OffcanvasHeader,
    OrderDetails: 'A',
    OrderGuestForm: 'A',
    PriceBlock: PriceBlock,
    ProductStickyFooter: 'B',
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    QuantityInputGroup: QuantityInputGroup,
    ServiceContactsSubpage: ServiceContactsSubpage,
    SimpleSearchForm: 'A',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
