import './MobileNavigationOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logoSmall from './images/logo-small.png';
import logoSmall15x from './images/logo-small@1.5x.png';
import logoSmall2x from './images/logo-small@2x.png';
import { SocialIcons } from './SocialIcons';
import { itemFilter } from './util';

interface MobileNavigationOffcanvasProps extends b2x.OffcanvasProps {}

const MobileNavigationOffcanvas = (props: MobileNavigationOffcanvasProps) => {
  const menu = b2x.useMenu('MENU_HEADER_MOBILE', { deepFilter: itemFilter });

  return (
    <b2x.Offcanvas {...props}>
      <div className="mobile-navigation-offcanvas d-flex flex-column h-100 bg-white">
        <Header />
        <div className="flex-grow-1 position-relative">
          <b2x.Drawers>
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">
                <div className="search-container py-3 px-3">
                  <div className="rounded-pill overflow-hidden border border-gray-200">
                    <b2x.SimpleSearchForm submitIconName="search" />
                  </div>
                </div>
                <div className="menu-container pb-5">{menu && <DrawerMenuItems item={menu} />}</div>
              </div>
              <Footer />
            </div>
          </b2x.Drawers>
        </div>
      </div>
    </b2x.Offcanvas>
  );
};

const Header = () => {
  const { close } = b2x.useModalContext();

  return (
    <div className="mobile-navigation-offcanvas-header">
      <div className="bg-primary" style={{ height: 30 }}></div>
      <div className="px-3 d-flex justify-content-between">
        <b2x.router.Link onClick={close} to="/">
          <b2x.ImageFromSrc
            className="logo"
            fluid
            src={{
              xs: [
                { density: 1, src: logoSmall },
                { density: 1.5, src: logoSmall15x },
                { density: 2, src: logoSmall2x },
              ],
            }}
            width={80}
          />
        </b2x.router.Link>
        <Button iconEnd={{ name: 'delete', size: 20 }} onClick={close} variant="blank" />
      </div>
    </div>
  );
};

interface FooterProps {}

const Footer = (props: FooterProps) => {
  const { info } = b2x.useAppContext();

  return (
    <div className="mobile-navigation-offcanvas-footer border-top border-gray-200 bg-gray-100 small">
      <div className="drawer-locale-container border-bottom px-3">
        <DrawerLocaleItem />
      </div>
      <div className="border-bottom p-3">
        <div className="px-3">
          <h6 className="primary-font fw-bold small">{t('misc.customerService')}</h6>
          {info?.customerCareEmail && (
            <div className="fw-medium mb-1">
              <Icon className="me-2" name="email" size={16} />
              {info.customerCareEmail}
            </div>
          )}
          {info?.customerCarePhoneNumber && (
            <div className="fw-medium mb-1">
              <Icon className="me-2" name="customer" size={16} />
              {info.customerCarePhoneNumber}
            </div>
          )}
        </div>
      </div>
      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center px-3">
          <div>
            <h6 className="primary-font fw-bold small m-0">{t('misc.followUs')}</h6>
          </div>
          <div className="text-secondary">
            <SocialIcons />
          </div>
        </div>
      </div>
    </div>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = t('misc.back') }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <div className="px-3 mt-3">
      <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
        <b2x.DrawerItem
          center={{ center: label }}
          className="bg-gray-100 px-2 gap-2 extra-small"
          height={35}
          justifyContent="start"
          start={{ center: <Icon name="small-arrow-left" /> }}
        />
      </b2x.DrawerButtonItem>
    </div>
  );
};

interface DrawerMenuItemsProps {
  depth?: number;
  item: b2x.MenuApiDto;
}

const DrawerMenuItems = ({ depth = 1, item }: DrawerMenuItemsProps) => {
  const { shippingCountry } = b2x.useAppContext();

  const childrenItemFiltered = item.children.filter(
    (children) => shippingCountry?.code === 'IT' || (shippingCountry?.code !== 'IT' && !children.tags?.includes('IT'))
  );

  const isLastBranch = React.useMemo(
    () => childrenItemFiltered.every((child) => child.children.length === 0),
    [childrenItemFiltered]
  );

  return (
    <div className="px-3">
      {childrenItemFiltered.map((child, index) => {
        const hasChildren = child.children.length > 0;
        //const childTextColor = child.tags?.includes('primary') ? ' : 'text-black';

        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={child.id}
            newDrawer={
              <Drawer backItemProps={{ label: child.label }}>
                <DrawerMenuItems depth={depth + 1} item={child} />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames(
                { 'text-uppercase': depth === 1 },
                { 'border-top border-gray-200': depth === 1 && index === 0 },
                'small px-3'
              )}
              end={{ center: <Icon name="small-arrow-right" /> }}
              height={isLastBranch ? 40 : 50}
              justifyContent="between"
              start={{ center: child.label }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerLinkItem key={child.id} to={child.link}>
            <b2x.DrawerItem
              className={classnames({ 'text-uppercase fw-bold': depth === 1 }, 'px-3 small')}
              height={isLastBranch ? 40 : 50}
              justifyContent="start"
              start={{ center: child.label }}
            />
          </b2x.DrawerLinkItem>
        );
      })}
      {item.link && (
        <b2x.DrawerLinkItem to={item.link}>
          <b2x.DrawerItem
            className="bg-primary small px-3 text-decoration-underline fw-bold"
            height={isLastBranch ? 40 : 50}
            justifyContent="start"
            start={{ center: t('misc.viewAll') }}
          />
        </b2x.DrawerLinkItem>
      )}
    </div>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

const DrawerLocaleItem = () => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <b2x.DrawerTriggerItem
      newDrawer={
        <Drawer>
          <div className="px-3 pt-4">
            <b2x.LocaleForm singleColumn />
            <div className="mt-3 text-center">
              <p>{t('misc.changeCountryMessage')}</p>
            </div>
          </div>
        </Drawer>
      }
    >
      <b2x.DrawerItem
        className="small px-3"
        end={{ center: <Icon name="small-arrow-right" /> }}
        height={40}
        justifyContent="between"
        start={{
          center: (
            <div className="hstack gap-2 small">
              <span>{t('header.sendTo', { country: shippingCountry?.code, locale: locale?.code })}</span>
            </div>
          ),
        }}
      />
    </b2x.DrawerTriggerItem>
  );
};

export const useMobileNavigationOffcanvas = (props?: MobileNavigationOffcanvasProps) =>
  b2x.useStaticModal(MobileNavigationOffcanvas, 'offcanvas', props);
