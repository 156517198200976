import { b2x } from '@b2x/react/src';

export const displayMenuItem = (tags: Array<string>): boolean => {
  const { shippingCountry } = b2x.useAppContext();

  // Recupero tutti i tag che iniziano per SHOW-ON-
  const showOn = tags
    .filter((tag) => tag.toUpperCase().startsWith('SHOW-ON-'))
    .map((tag) => tag.toUpperCase().replace('SHOW-ON-', ''));

  // Recupero tutti i tag che iniziano per HIDE-ON-
  const hideOn = tags
    .filter((tag) => tag.toUpperCase().startsWith('HIDE-ON-'))
    .map((tag) => tag.toUpperCase().replace('HIDE-ON-', ''));

  // Se tra i tag hideOn è presente la countryCode corrente setto FALSE
  // Se showOn contiene dei valori e se uno tra questi non metcha con la countryCode corrente setto FALSE
  // Negli altri casi la voce è da mostrare

  let display = true;

  if (shippingCountry?.code && hideOn.includes(shippingCountry.code)) {
    display = false;
  } else if (shippingCountry?.code && showOn.length > 0 && !showOn.includes(shippingCountry.code)) {
    display = false;
  }

  // console.clear();
  // console.log(`current country code: ${shippingCountry?.code}`);
  // console.log(`show on lenght: ${showOn.length}`);
  // showOn.length > 0 && console.log(showOn);
  // console.log(`hide on lenght: ${hideOn.length}`);
  // hideOn.length > 0 && console.log(hideOn);
  // display ? console.log('voce da mostrare') : console.log('voce da nascondere');

  return display;
};

export const itemFilter = (item: b2x.MenuApiDto): boolean => {
  const shippingCountry = b2x.staticVariables.shippingCountry;

  // Recupero tutti i tag che iniziano per SHOW-ON-
  const showOn = item.tags
    ?.filter((tag) => tag.toUpperCase().startsWith('SHOW-ON-'))
    .map((tag) => tag.toUpperCase().replace('SHOW-ON-', ''));

  // Recupero tutti i tag che iniziano per HIDE-ON-
  const hideOn = item.tags
    ?.filter((tag) => tag.toUpperCase().startsWith('HIDE-ON-'))
    .map((tag) => tag.toUpperCase().replace('HIDE-ON-', ''));

  return shippingCountry?.code && hideOn?.includes(shippingCountry.code)
    ? false
    : shippingCountry?.code && showOn && showOn.length > 0 && !showOn.includes(shippingCountry.code)
    ? false
    : true;
};
